<template>
  <div>
    <NuxtLayout name="default">
      <!-- section hero -->
      <SectionHeroNew
        v-if="$page?.homePage.heroImage"
        :background-image="$page?.homePage.heroImage"
        :background-image-alt="$page?.homePage.heroImageAlt"
        :title-first-half="$page?.homePage.titleStart"
        :title-second-half="$page?.homePage.titleEnd"
        :title-middle-options="($page?.homePage.titleLine as string[])"
        :cta-text="$page?.homePage.heroCta.text"
        :cta-link="$page?.homePage.heroCta.uri"
      ></SectionHeroNew>

      <!-- homepage-redesign-marketing-section -->
      <SectionMarketing
        v-if="$page?.homePage.campaignTitle"
        :title="$page?.homePage.campaignTitle"
        :description="$page?.homePage.campaignDescription"
        :image="$page?.homePage.campaignImage"
        :alt="$page?.homePage.campaignImageAlt"
        :cta="$page?.homePage.campaignCta"
      ></SectionMarketing>

      <!-- reputations section  -->
      <section
        class="container py-space-lg py-lg-space-xl"
        v-if="$page?.homePage.reputationTitle"
      >
        <div class="row d-flex">
          <h2
            class="col-12 col-lg-10 flex-grow h2-large mb-space-sm mb-lg-space-lg"
          >
            {{ $page?.homePage.reputationTitle }}
          </h2>
          <div class="col-2 d-none d-lg-flex justify-content-end">
            <div
              class="d-none d-lg-flex justify-content-center align-items-center rankings-title-number bg-secondary fw-bold rounded-circle"
            >
              {{ $page?.homePage.reputationTitleStat }}
            </div>
          </div>
          <SectionInviewNew
            v-for="(card, index) in $page?.homePage.reputationCards"
            :body="card?.text"
            :title="card?.title"
            :cta-link="card?.link?.uri"
            :cta-text="card?.link?.text"
            :container-background-image="card?.image"
            cta-tag="button"
            :content-alignment="index == 1 ? 'right' : 'left'"
            @cta-click="($event) => handleRankingCtaClick($event)"
            class="col-12"
            :class="{
            'mb-space-sm mb-lg-space-lg':
              index !== $page!.homePage.reputationCards.length - 1,
          }"
          />
        </div>
        <div class="row"></div>
      </section>

      <!-- section-testimonial -->
      <SectionTestimonialColumns
        v-if="$page?.homePage.testimonialTitle"
        :title="$page?.homePage.testimonialTitle"
        :items="$page?.homePage.testimonialCards"
      ></SectionTestimonialColumns>

      <!-- section-program-interest -->
      <SectionProgramInterestNew
        v-if="$page?.homePage.interestAreaTitle"
        :title="$page?.homePage.interestAreaTitle"
        :description="$page?.homePage.interestAreaDescription"
        :items="$page?.allInterestAreaType"
      ></SectionProgramInterestNew>

      <!-- section-information -->
      <SectionInformationNew
        v-if="$page?.coreExploreInformation.exploreItems"
        :items="$page?.coreExploreInformation.exploreItems"
        id="section-information"
        @selectItem="handleSelectItemClick"
        @linkClick="
          ($event, items) =>
            handleLinkClick($event, items, 'find information about')
        "
        @updateItem="
          ($event, items) =>
            handleUpdateItemClick(items, 'find information about')
        "
      ></SectionInformationNew>

      <!-- section-rankings-hightlights -->
      <SectionRankingsHighlights
        v-if="$page?.homePage.accoladesTitle"
        :title="$page?.homePage.accoladesTitle"
        :description="$page?.homePage.accoladesDescription"
        :link="$page?.homePage.accoladesLink!"
        :background-image="$page?.homePage.accoladesBackgroundImage"
        :list-item="$page?.homePage.accoladesListItem"
      ></SectionRankingsHighlights>

      <!-- section-trending-carousel -->
      <SectionTrendingCarousel
        v-if="$page?.homePage.trendingTitle"
        :title="$page?.homePage.trendingTitle"
        :cards="$page?.homePage.trendingCards"
      >
        <template
          #card-content="{ card, playButton, toggleProgress, progressWtsVar }"
        >
          <TrendingCards
            :play-button="playButton"
            :toggle-progress="toggleProgress"
            :progress-wts-var="progressWtsVar"
            :title="card?.title!"
            :category="card?.category!"
            :img="card?.image!"
            :description="card?.description!"
            :link="card?.link!"
            :section-title="$page?.homePage.trendingTitle!"
          ></TrendingCards>
        </template>
      </SectionTrendingCarousel>

      <!-- RFI Component -->
      <SectionRfiMultistepNew
        :bg-image="RfiBgImage"
        class="mb-space-lg mb-lg-space-xxl"
      ></SectionRfiMultistepNew>
    </NuxtLayout>
  </div>
</template>

<script setup lang="ts">
import RfiBgImage from "@/assets/img/new/home-page/RFI-Image-v2.jpg";
import SectionTrendingCarousel from "@/components/home-page/SectionTrendingCarousel.vue";
import TrendingCards from "@/components/home-page/TrendingCards.vue";
import { useSchemaService } from "@/composables/useSchemaService";
import { analyticsComposable } from "@rds-vue-ui/analytics-gs-composable";
import SectionHeroNew from "~/components/home-page/SectionHeroNew.vue";
import SectionInformationNew, {
  type ExploreItemLinkEvent,
} from "~/components/home-page/SectionInformationNew.vue";
import SectionInviewNew, {
  type CtaClickPayload,
} from "~/components/home-page/SectionInviewNew.vue";
import SectionMarketing from "~/components/home-page/SectionMarketing.vue";
import SectionProgramInterestNew from "~/components/home-page/SectionProgramInterestNew.vue";
import SectionRankingsHighlights from "~/components/home-page/SectionRankingsHighlights.vue";
import SectionRfiMultistepNew from "~/components/home-page/SectionRfiMultistepNew.vue";
import SectionTestimonialColumns from "~/components/home-page/SectionTestimonialColumns.vue";
import { useUtilityService } from "~/composables/useUtilityService";
import {
  CoreExploreInformation,
  HomePage,
  InterestAreaType,
} from "~/server/resolvers/resolvers-types";

const UtilityService = useUtilityService();

const config = useRuntimeConfig();

interface HomePageQueryResponse {
  homePage: HomePage;
  allInterestAreaType: InterestAreaType[];
  coreExploreInformation: CoreExploreInformation;
}

const $page = await usePageQuery<HomePageQueryResponse>(
  `#graphql
      query HomePage(
      $homePageId: ID!
      $coreExploreInformationID: ID!
      $sort: sortInput) {
        homePage(id: $homePageId) {
          id
          detailPage
          heroImage
          heroImageAlt
          title
          titleStart
          titleLine
          titleEnd
          heroCta {
            target
            uri
            text
          }
          metaTitle
          metaDescription
          campaignTitle
          campaignDescription
          campaignImage
          campaignImageAlt
          campaignCta {
            text
            target
            uri
          }
          reputationTitle
          reputationTitleStat
          reputationCards {
            title
            image
            text
            link {
              text
              target
              uri
            }
          }
          testimonialTitle
          testimonialCards {
            name
            quote
            designation
            designationLineTwo
            image
            imageAlt
          }
          interestAreaTitle
          interestAreaDescription
          exploreTitle
          accoladesTitle
          accoladesDescription
          accoladesLink {
            text
            uri
          }
          accoladesBackgroundImage
          accoladesListItem {
            title
            description
          }
          trendingTitle
          trendingCards {
            title
            category
            description
            image
            imageAlt
            link {
              text
              target
              uri
            }
          }
        }
        allInterestAreaType(sort: $sort) {
          id
          title
          description
          detailPage
          image
        }
        coreExploreInformation(id: $coreExploreInformationID) {
          id
          exploreItems {
            id
            title
            image
            imageAlt
            description
            listItems {
              uri
              text
              target
            }
          }
        }
      }`,
  {
    homePageId: "bf031eca-3088-48fb-9fc4-a332b990dfc7",
    coreExploreInformationID: "a6c607c9-6ddb-44c7-a093-9f6cc57ce791",
    sort: {
      by: "title",
      order: "ASC",
    },
  },
  {
    key: "home-page",
  }
);

definePageMeta({
  layout: false,
  __dangerouslyDisableSanitizersByTagID: {
    "ldjson-schema": ["innerHTML"],
  },
});

useHead({
  title: $page?.homePage.metaTitle,
  meta: [
    { name: "Title", content: $page?.homePage.metaTitle },
    {
      name: "Description",
      content: $page?.homePage.metaDescription,
    },
  ],
  link: [
    {
      rel: "canonical",
      href: UtilityService.formatCanonicalUrl(config.public.siteUrl),
    },
  ],
  script: [useSchemaService().generateHomePageSchema()],
});

const selectedItem = ref(
  $page?.coreExploreInformation.exploreItems
    ? $page?.coreExploreInformation.exploreItems[0]?.title
    : ""
);

const handleSelectItemClick = (event: Event, item: string, state: string) => {
  const title = "find information about";
  analyticsComposable.trackCollapseEvent(
    "onclick",
    state,
    "click",
    "main content",
    title.toLowerCase(),
    `find information about ${selectedItem?.value?.toLowerCase()}`
  );
};

const handleUpdateItemClick = (item: string, section: string) => {
  selectedItem.value = item;
  analyticsComposable.trackSelectEvent(
    "onclick",
    "click",
    "find information about",
    "main content",
    section.toLowerCase(),
    item.toLowerCase()
  );
};

const handleLinkClick = (
  event: Event,
  item: ExploreItemLinkEvent,
  section: string
) => {
  analyticsComposable.trackLinkEvent(
    "internal link",
    "main content",
    `${section} ${selectedItem.value?.toLowerCase()}`,
    item.text!.toLowerCase()
  );
  if (process.client && event.target instanceof HTMLElement) {
    const linkHref = event.target.getAttribute("href");
    const linkTarget = event.target.getAttribute("target");

    if (linkHref) {
      if (linkTarget == "_self") {
        navigateTo(linkHref);
      } else {
        navigateTo(linkHref, {
          open: {
            target: linkTarget ? linkTarget : "_blank",
          },
        });
      }
    }
  }
};

const handleRankingCtaClick = (event: CtaClickPayload) => {
  const analyticsData = event.analyticsData;
  analyticsData.component = $page?.homePage.reputationTitle
    ? $page?.homePage.reputationTitle.toLowerCase()
    : "";
  analyticsComposable.trackEvent(analyticsData);
  navigateTo(event.ctaLink);
};
</script>

<style lang="scss" scoped>
.rankings-title-number {
  height: 112px;
  width: auto;
  aspect-ratio: 1;
  font-size: 60px;
}
</style>
