<template>
  <div :style="progressWtsVar">
    <div class="row">
      <!-- left column -->
      <div class="col-12 col-lg-8 mb-space-sm mb-lg-0">
        <div
          class="position-relative card-img h-100"
          :style="{ 'background-image': `url(${img})` }"
        >
          <!-- play/pause button -->
          <button
            class="d-none btn pause-button px-0 py-space-xxs d-lg-flex justify-content-center position-absolute"
            @click="toggleProgress"
            :aria-label="
              playButton === 'pause' ? 'pause animation' : 'resume animation'
            "
          >
            <font-awesome-icon
              :icon="['fas', playButton]"
              class="w-100 h-100"
            ></font-awesome-icon>
          </button>
          <!-- progress bar -->
          <div
            class="d-none d-lg-block position-absolute w-100 progress-bar-container"
          >
            <div class="progress-bar py-space-xxxs d-block bg-light-4"></div>
          </div>
        </div>
      </div>
      <!-- right column -->
      <div class="col-12 col-lg-4">
        <div class="ps-lg-space-md pt-lg-space-xxl">
          <div class="text-dark-1 fs-small mb-space-xxs mb-lg-space-xs">
            {{ category }}
          </div>
          <h3 class="h3-large mb-space-xs mb-lg-space-sm">
            {{ title }}
          </h3>
          <div v-html="description" class="mb-space-xs mb-lg-space-sm"></div>
          <NuxtLink
            :to="link?.uri"
            class="rds-link fs-small fw-bold"
            @click="handleLinkClick"
            >{{ link?.text }}</NuxtLink
          >
        </div>
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import { ParagraphHtmlLink } from "@/server/resolvers/resolvers-types";
import { analyticsComposable } from "@rds-vue-ui/analytics-gs-composable";
import type { ProgressWtsVarType } from "./SectionTrendingCarousel.vue";

/**
 * @typedef {Readonly<SlotPropType>}
 * @summary slotProps getting inherited from SectionTrendingCarousel to keep necessary data between parent and child component consistent
 * @arg {Ref<number>} progressWidth - width of progress bar (between 0 to 100)
 * @arg {Ref<'pause'|'play'>} playButton - represent current status of play button (pause | play), contains analytics tag
 * @arg {() => void} toggleProgress - pause/resume increment in progress bar width
 * @arg {ComputedRef<ProgressWtsVarType>} progressWtsVar - computed property returns current status of progress bar width as css
 */

const props = withDefaults(
  defineProps<{
    // props inherited from carousel
    playButton: "pause" | "play";
    toggleProgress: () => void;
    progressWtsVar: ProgressWtsVarType;
    // card content props
    title: string;
    img: string;
    category: string;
    description: string;
    link: ParagraphHtmlLink;
    sectionTitle?: string;
  }>(),
  {}
);

/**
 * @arg {props}
 * @return {null}
 * @summary fires analytics composable on click
 */
const handleLinkClick = async () => {
  const { title, link, sectionTitle } = props;
  analyticsComposable.trackLinkEvent(
    "internal link",
    "main content",
    title.toLowerCase(),
    link.text.toLowerCase(),
    sectionTitle?.toLowerCase()
  );
};
</script>

<style scoped lang="scss">
.card-img {
  background-repeat: no-repeat;
  background-position: center top;
  background-size: cover;
  min-height: 220px;
  // set height in desktop device to be 387px
  @media screen and (min-width: 768px) {
    min-height: 387px;
  }
}
.pause-button {
  aspect-ratio: 1;
  bottom: 20px;
  left: 12px;
  height: 30px;
  @supports (aspect-ratio: 1) {
    width: auto;
  }
  @supports not (aspect-ratio: 1) {
    max-width: 30px !important;
  }
  background-color: rgba(var(--rds-white-rgb), 0.8);
  &:active {
    background-color: rgba(var(--rds-white-rgb), 0.8);
  }
}
.progress-bar-container {
  bottom: 0;
}
.progress-bar {
  position: relative;

  &::before {
    content: "";
    position: absolute;
    bottom: 0;
    left: 0;
    height: 100%;
    background-color: var(--rds-secondary, #ffc627);
    width: var(--progress-wts, 50%);
  }
}
</style>
