<template>
  <section class="position-relative">
    <div class="background-image position-absolute">
      <img
        class="sh-hero-image rds-lazy position-absolute"
        :data-src="backgroundImage"
        alt="rankings-background"
      />
    </div>

    <div
      class="container rds-lazy text-white position-relative pt-lg-space-xxl pb-lg-space-lg pt-space-lg px-space-lg"
    >
      <div class="row justify-content-between">
        <div class="col-lg-5 col-12">
          <h2 class="pb-space-xs">{{ title }}</h2>
          <div class="pb-space-xs mb-0" v-html="description"></div>
          <NuxtLink
            :to="link.uri!"
            class="text-secondary text-decoration-underline fw-bold"
            @click="handleClick"
            >{{ link.text }}</NuxtLink
          >
        </div>

        <div class="col-lg-6 col-12 mt-space-lg mt-lg-0">
          <div class="row">
            <div class="col-lg-6 col-12 pb-space-lg" v-for="item in listItem">
              <h1 class="h1-large accordian-title pb-space-xxs">
                {{ item?.title }}
              </h1>
              <div class="mb-0" v-html="item?.description"></div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script setup lang="ts">
import {
  Maybe,
  ParagraphHtmlLink,
  ParagraphListItem,
} from "@/server/resolvers/resolvers-types";
import {
  analyticsComposable,
  type AnalyticsEventObject,
} from "@rds-vue-ui/analytics-gs-composable";

const props = defineProps<{
  title: string;
  description: string;
  backgroundImage: string;
  link: ParagraphHtmlLink;
  listItem: Maybe<ParagraphListItem>[];
}>();

const handleClick = (event: Event) => {
  const { title, link } = props;
  const eventObject: AnalyticsEventObject = {
    event: "link",
    action: "click",
    name: "onclick",
    type: "internal link",
    region: "main content",
    section: title.toLowerCase(),
    text: link.text?.toLowerCase(),
  };
  analyticsComposable.trackEvent(eventObject);
};
</script>

<style scoped>
.sh-hero-image {
  width: 100%;
  height: 100%;
  object-fit: cover;
  display: block;
  object-position: 100% 0;
  object-position: center top;
}
.background-image {
  grid-area: 1 / 1;
  width: 100%;
  height: 100%;
  overflow: hidden;
  z-index: -1;
  &::after {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: #000000 0% 0% no-repeat padding-box;
    opacity: 0.49;
  }
}
@media (max-width: 768px) {
  .accordian-title {
    font-size: 56px !important;
  }
}
</style>
