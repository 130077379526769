<template>
  <section class="homepage-hero position-relative">
    <div class="background-image bg-dark-3">
      <img
        fetchpriority="high"
        class="sh-hero-image"
        :src="backgroundImage"
        :alt="backgroundImageAlt"
      />
    </div>
    <div class="container h-100">
      <div
        class="row flex-column h-100 justify-content-end align-items-end pb-space-xl"
      >
        <div class="col-12 d-flex flex-column align-items-start">
          <div class="pb-space-sm pb-lg-space-md">
            <h1
              class="text-white h1-xl d-flex flex-column justify-content-center flex-wrap"
              :aria-label="accesibleTitle"
            >
              <span class="align-items-center d-flex flex-wrap">
                <span class="title-first-half">{{ titleFirstHalf }}</span>
                <span
                  class="title-phrase-container ms-lg-space-xs d-inline-block text-dark-3"
                  :style="phraseContainerStyle"
                >
                  <Transition @after-leave="resetContainerWidth">
                    <span
                      class="active-phrase d-inline-block px-space-xs bg-secondary"
                      ref="activePhraseRef"
                      :key="titleMiddleOptions[activePhraseIndex]"
                      >{{ titleMiddleOptions[activePhraseIndex] }}</span
                    >
                  </Transition>
                </span>
              </span>
              <span>{{ titleSecondHalf }}</span>
            </h1>
          </div>
        </div>
        <div class="col-12 d-flex justify-content-between align-items-center">
          <NuxtLink
            :to="ctaLink"
            class="btn btn-secondary btn-lg fs-large"
            @click="handleCtaClick"
          >
            {{ ctaText }}
          </NuxtLink>
          <button
            class="btn pause-button p-space-xxs d-flex justify-content-center"
            @click="handleAnimationToggle"
            v-if="preferredMotion !== 'reduce'"
            :aria-label="
              isActive ? 'pause title animation' : 'resume title animation'
            "
          >
            <font-awesome-icon
              :icon="['fas', pauseButtonIcon]"
              class="w-100 h-100"
            ></font-awesome-icon>
          </button>
        </div>
      </div>
    </div>
  </section>
</template>

<script setup lang="ts">
import { analyticsComposable } from "@rds-vue-ui/analytics-gs-composable";
import { useIntervalFn, usePreferredReducedMotion } from "@vueuse/core";

interface Props {
  titleFirstHalf: string;
  titleSecondHalf: string;
  titleMiddleOptions: string[];
  backgroundImage: string;
  backgroundImageAlt: string;
  ctaText: string;
  ctaLink: string;
}

const props = defineProps<Props>();

const activePhraseIndex = ref(0);
const activePhraseRef = ref<HTMLElement>();
const phraseContainerStyle = ref<string | null>("");
const previousPhraseWidth = ref(0);
const pauseButtonIcon = ref<"pause" | "play">("pause");

const preferredMotion = usePreferredReducedMotion();
const { pause, resume, isActive } = useIntervalFn(() => {
  if (preferredMotion.value !== "reduce") {
    activePhraseIndex.value < props.titleMiddleOptions.length - 1
      ? activePhraseIndex.value++
      : (activePhraseIndex.value = 0);
  }
}, 2000);

// generating accesible title for screen readers and GA event
const accesibleTitle = computed(() => {
  let assembledTitle = props.titleFirstHalf;
  for (let i = 0; i < props.titleMiddleOptions.length; i++) {
    assembledTitle += ` ${props.titleMiddleOptions[i]},`;
  }
  assembledTitle += ` ${props.titleSecondHalf}`;
  return assembledTitle;
});

// assists with accesibility, after transition we don't need explicit height and width
const resetContainerWidth = () => {
  phraseContainerStyle.value = `
      --phrase-container-height: auto;
      --phrase-container-width: auto;
      `;
};

const handleCtaClick = () => {
  analyticsComposable.trackLinkEvent(
    "internal link",
    "main content",
    accesibleTitle.value.toLowerCase(),
    props.ctaText.toLowerCase()
  );
};

const handleAnimationToggle = () => {
  analyticsComposable.trackLinkEvent(
    "internal link",
    "main content",
    accesibleTitle.value.toLowerCase(),
    isActive.value ? "pause button" : "play button"
  );
  isActive.value ? pause() : resume();
};

watch(
  activePhraseRef,
  (newValue) => {
    // logic makes sure we always set width to largest element when transitioning
    if (newValue && preferredMotion.value !== "reduce") {
      let newWidth =
        previousPhraseWidth.value <= newValue.getBoundingClientRect().width
          ? newValue.getBoundingClientRect().width
          : previousPhraseWidth.value;
      phraseContainerStyle.value = `
  --phrase-container-height: ${newValue.getBoundingClientRect().height}px;
  --phrase-container-width: ${newWidth}px;
  `;
      previousPhraseWidth.value = newValue.getBoundingClientRect().width;
    } else {
      resetContainerWidth();
    }
  },
  { immediate: true }
);

watch(isActive, (newValue) => {
  newValue === false
    ? (pauseButtonIcon.value = "play")
    : (pauseButtonIcon.value = "pause");
});
</script>

<style scoped>
.homepage-hero {
  height: 512px;
  @media (min-width: 992px) {
    & {
      height: 684px;
    }
  }
}

.sh-hero-image {
  width: 100%;
  height: 100%;
  object-fit: cover;
  display: block;
  object-position: center top;
}

.background-image {
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  overflow: hidden;
  position: absolute;
  z-index: -1;
  &::after {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: var(--rds-black) 0% 0% no-repeat padding-box;
    opacity: 0.4;
    z-index: 1; /* Place the gradient above the image */
  }
}

.title-first-half {
  flex-basis: 100%;
  @media (min-width: 992px) {
    flex-basis: auto;
  }
}

.title-phrase-container {
  position: relative;
  width: var(--phrase-container-width, auto);
  height: var(--phrase-container-height, auto);
  overflow-y: hidden;
}

.pause-button {
  aspect-ratio: 1;
  height: 36px;
  width: auto;
  background-color: rgba(var(--rds-white-rgb), 0.8);
  &:active {
    background-color: rgba(var(--rds-white-rgb), 0.8);
  }
}

/* Animation for text slide in/ slide out */
.v-enter-active,
.v-leave-active {
  position: absolute;
  transition: opacity 800ms ease, transform 800ms ease;
}
.v-leave-to {
  transform: translateY(-150%);
}
.v-enter-from {
  transform: translateY(150%);
}
</style>
