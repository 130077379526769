<template>
  <div
    class="position-relative container overflow-hidden px-gutter"
    ref="contentContainer"
  >
    <div
      class="position-relative overflow-hidden row content-container py-space-lg py-lg-space-xxxl px-gutter px-lg-space-xxl mx-auto align-items-center"
      :class="contentContainerClasses"
    >
      <div
        class="col-12 background-image-container position-absolute h-100 w-100 rds-lazy"
        :class="backgroundImageClasses"
        :data-background-image="containerBackgroundImage"
      ></div>
      <div
        class="col-12 col-lg-5 py-space-sm py-lg-space-md px-gutter px-lg-space-sm"
        :class="contentClasses"
      >
        <h3 class="mb-space-sm h3-xl">{{ title }}</h3>
        <div v-if="body" v-html="body"></div>
        <component
          :is="ctaTagName"
          @click="handleCtaClick"
          v-if="displayCta"
          :href="ctaTag == 'a' && ctaLink ? ctaLink : null"
          :class="ctaClassesComp"
          >{{ ctaText }}</component
        >
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import { AnalyticsEventObject } from "@rds-vue-ui/analytics-gs-composable";
import { useIntersectionObserver } from "@vueuse/core";

interface Props {
  title: string;
  body: string;
  ctaText?: string;
  ctaLink?: string;
  ctaTag?: "a" | "button";
  ctaStyle?: "link" | "button";
  ctaClasses?: string;
  ctaVariant?: string;
  ctaLinkTarget?: "_blank" | "_self" | "_parent" | "_top";
  containerBackgroundImage: string;
  contentAlignment?: "left" | "right";
  contentBackgroundVariant?: string;
}

export interface CtaClickPayload {
  analyticsData: AnalyticsEventObject;
  ctaLink?: string;
}

const props = withDefaults(defineProps<Props>(), {
  ctaTag: "a",
  ctaStyle: "link",
  ctaVariant: "primary",
  contentAlignment: "left",
  contentBackgroundVariant: "white",
});

const emits = defineEmits<{
  /**
   *  emitted when the cta is clicked.
   */
  (e: "ctaClick", payload: CtaClickPayload): void;
}>();

const alignMap = {
  left: "start",
  right: "end",
};

const contentContainer = ref<HTMLElement | null>(null);
const contentContainerIsVisible = ref(false);

const { stop } = useIntersectionObserver(
  contentContainer,
  ([{ isIntersecting }], observerElement) => {
    if (isIntersecting === true) {
      contentContainerIsVisible.value = isIntersecting;
      stop();
    }
  },
  {
    threshold: 0.5,
  }
);

const backgroundImageClasses = computed(() => {
  let classes = "";
  contentContainerIsVisible.value ? (classes += `visibility-animation`) : null;
  return classes;
});

const contentContainerClasses = computed(() => {
  return ` justify-content-${alignMap[props.contentAlignment]} `;
});

const contentClasses = computed(() => {
  let classes = ` bg-${props.contentBackgroundVariant} `;

  contentContainerIsVisible.value
    ? (classes += ` slide-from-${props.contentAlignment} `)
    : (classes += ` slide-to-${props.contentAlignment} `);
  return classes;
});

const displayCta = computed(() => {
  if (props.ctaText) {
    if (props.ctaLink || props.ctaTag == "button") {
      return true;
    } else {
      console.log(
        "cta missing text, or link value",
        `text: ${props.ctaText}`,
        `link: ${props.ctaLink}`
      );
      return false;
    }
  }
});

const ctaTagName = computed(() => {
  return props.ctaTag === "button" ? "button" : "a";
});

const ctaClassesComp = computed(() => {
  let classes = " ";
  if (props.ctaStyle == "button") {
    classes += ` btn btn-${props.ctaVariant} `;
  } else if (props.ctaTag == "button") {
    classes += ` btn btn-link text-${props.ctaVariant} fw-bold text-decoration-underline px-0 py-0`;
  } else {
    classes += ` text-${props.ctaVariant} fw-bold text-decoration-underline `;
  }
  props.ctaClasses ? (classes += ` ${props.ctaClasses} `) : null;
  return classes;
});

const handleCtaClick = () => {
  const analyticsData: AnalyticsEventObject = {
    event: "link",
    action: "click",
    name: "onclick",
    type: "internal link",
    region: "main content",
    section: props.title ? props.title.toLowerCase() : "",
    text: props.ctaText ? props.ctaText.toLowerCase() : "",
  };
  const ctaClickPayload: CtaClickPayload = {
    analyticsData: analyticsData,
  };
  props.ctaLink ? (ctaClickPayload.ctaLink = props.ctaLink) : null;
  emits("ctaClick", ctaClickPayload);
};
</script>

<style scoped lang="scss">
.content-container {
  min-height: 528px;
}

.background-image-container {
  background-size: cover;
  background-position: center top;
  top: 0;
  left: 0;
  transition: transform 500ms 50ms ease-in-out;
  transform-origin: center;
  transform: scale(110%);
  &.visibility-animation {
    transform: scale(100%);
  }
  @media (prefers-reduced-motion) {
    transition: transform 0;
    transform: scale(100%);
  }
}

.slide-from-left,
.slide-from-right {
  transition: transform 500ms 100ms ease-out;
  transform: translateX(0);
}
.slide-to-left,
.slide-to-right {
  transition: transform 500ms;
}
.slide-to-left {
  transform: translateX(-125%);
}
.slide-to-right {
  transform: translateX(125%);
}

@media (prefers-reduced-motion) {
  .slide-from-left,
  .slide-from-right,
  .slide-to-left,
  .slide-to-right {
    transition: transform 0ms;
    transform: translateX(0);
  }
}
</style>
