<template>
  <section
    ref="sectionRef"
    class="container overflow-x-hidden py-space-lg py-lg-space-xxl"
    :style="getCssVariables"
  >
    <!-- title block -->
    <div class="row">
      <div class="col-12 mb-space-md">
        <h2 class="h2-large text-dark-3">{{ title }}</h2>
      </div>
    </div>
    <div class="slot-container mb-space-md mb-lg-space-lg position-relative">
      <Transition :name="slideDirection">
        <div :key="activeIdx" class="slot-wrapper">
          <slot
            name="card-content"
            :active-idx="activeIdx"
            :card="cards[activeIdx]"
            :play-button="pauseBtn"
            :toggle-progress="handleProgressToggle"
            :progress-wts-var="getCssVariables"
          ></slot>
        </div>
      </Transition>
    </div>
    <!-- controller block -->
    <div class="row">
      <div class="col-12">
        <div class="d-flex d-lg-inline-flex controller-block">
          <!-- controller progress bar: visible in mobile -->
          <div class="d-flex d-lg-none flex-column me-auto">
            <button
              class="mobile-play-btn mb-space-xxs"
              @click="handleProgressToggle"
              :aria-label="isActive ? 'pause animation' : 'resume animation'"
            >
              <font-awesome-icon
                :icon="['fas', pauseBtn]"
                class="w-100 h-100 text-dark-3"
              ></font-awesome-icon>
            </button>
            <div
              class="d-block bg-light-4 py-space-xxxs mobile-progress-bar mb-space-xxs"
            ></div>
            <div>{{ activeIdx + 1 }} of {{ cards.length }}</div>
          </div>
          <!-- control-buttons -->
          <button
            class="carousel-control"
            :disabled="activeIdx === 0"
            :class="{ disable: activeIdx === 0 }"
            @click="slidePrev"
          >
            <img alt="left-chevron" :src="Left" />
          </button>
          <button
            class="carousel-control"
            :disabled="activeIdx === cards.length - 1"
            :class="{ disable: activeIdx === cards.length - 1 }"
            @click="slideNext"
          >
            <img alt="right-chevron" :src="Right" />
          </button>
        </div>
      </div>
    </div>
  </section>
</template>

<script setup lang="ts" generic="T extends unknown">
import { ParagraphImageItem } from "@/server/resolvers/resolvers-types";
import { analyticsComposable } from "@rds-vue-ui/analytics-gs-composable";
import { useIntersectionObserver, useIntervalFn } from "@vueuse/core";
import { ref, Transition, watch } from "vue";
import Left from "~/assets/img/left.svg?inline";
import Right from "~/assets/img/right.svg?inline";

export type ProgressWtsVarType = {
  "--progress-wts": string;
};

const props = withDefaults(
  defineProps<{
    title: string;
    cards: T[];
  }>(),
  {}
);

const sectionRef = ref<HTMLElement | null>(null);
const activeIdx = ref<number>(0);
const progressWts = ref<number>(0);
const pauseBtn = ref<"pause" | "play">("pause");
const slideDirection = ref<"slide-next" | "slide-prev">("slide-next");

/**
 * @summary useIntervalFn from @vueuse setting increment of progressWts value. increment activeIdx once progressWts value reaches 100, until reaching last slide. interval from 0 to 100 set to 700ms
 */
const { pause, resume, isActive } = useIntervalFn(() => {
  // add 0.5% width value each increment
  progressWts.value += 0.5;
  // set max prigressWts to 120 instead of 100 to make 200ms delay after reaching 100
  if (progressWts.value === 120 && activeIdx.value < props.cards.length - 1) {
    if (activeIdx.value < props.cards.length - 1) {
      activeIdx.value += 1;
    }
    progressWts.value = 0;
  }
  // interval of 50ms 1000ms/50ms = 20 ticks
}, 50);

/**
 * @summary resume progress bar increment once component is in viewport, stops once it goes outside of viewport
 */
useIntersectionObserver(sectionRef, ([{ isIntersecting }]) => {
  if (isIntersecting) {
    resume();
  } else {
    pause();
  }
});

const currentCardTitle = computed<string>(() => {
  const currentCard = props.cards[activeIdx.value] as ParagraphImageItem;
  return currentCard.title!.toLowerCase();
});

const handleProgressToggle = () => {
  isActive.value ? pause() : resume();
  analyticsComposable.trackLinkEvent(
    "internal link",
    "main content",
    currentCardTitle.value,
    isActive.value ? "play button" : "pause button",
    props.title.toLowerCase()
  );
};

/**
 * @return {ComputedRef<ProgressWtsVarType>}
 * @summary set CSS variable values for setting container heights in px, and progress bar width in %
 */
const getCssVariables = computed<ProgressWtsVarType>(() => {
  const progressWidth = progressWts.value > 100 ? "100" : progressWts.value;
  return {
    "--progress-wts": `${progressWidth}%`,
  };
});

/**
 * @typedef {Readonly<SlotPropType>}
 * @summary slot props getting exposed from SectionTrendingCarousel to keep necessary data between parent and child component consistent
 * @arg {Ref<number>} activeIdx - represent current slide number (=== visible)
 * @arg {Ref<number>} progressWidth - width of progress bar (between 0 to 100)
 * @arg {Ref<'pause'|'play'>} playButton - represent current status of play button (pause | play), contains analytics tag
 * @arg {() => void} toggleProgress - pause/resume increment in progress bar width
 * @arg {ComputedRef<ProgressWtsVarType>} progressWtsVar - computed property returns current status of progress bar width as css variable
 */
interface SlotPropType {
  card: T;
  activeIdx: number;
  playButton: "pause" | "play";
  toggleProgress: () => void;
  progressWtsVar: ProgressWtsVarType;
}
const slots = defineSlots<{
  "card-content"(props: SlotPropType): any;
}>();

/**
 * @typedef {() => {}}
 * @summary click handler functions to update activeIdx num. conditional statements added to prevent activeIdx from going < 0 | > len(cards) - 1. contains analytics tags.
 */
const slidePrev = () => {
  analyticsComposable.trackSelectEvent(
    "onclick",
    "click",
    "carousel",
    "main content",
    props.title.toLowerCase(),
    "left chevron"
  );
  if (activeIdx.value > 0) {
    activeIdx.value -= 1;
    progressWts.value = 0;
    resume();
  }
};
const slideNext = () => {
  analyticsComposable.trackSelectEvent(
    "onclick",
    "click",
    "carousel",
    "main content",
    props.title.toLowerCase(),
    "right chevron"
  );
  if (activeIdx.value < props.cards.length - 1) {
    activeIdx.value += 1;
    progressWts.value = 0;
    resume();
  }
};

/**
 * @arg {progressWts}
 * @typedef {Ref<number>}
 * @return {() => void}
 * @summary a watcher returning activeIdx to 0 once it reaches last index with progressWts value of 100(%)
 */
watch(
  () => progressWts.value,
  (newVal) => {
    if (activeIdx.value === props.cards.length - 1 && newVal === 140) {
      activeIdx.value = 0;
      progressWts.value = 0;
    }
  }
);

/**
 * @arg {Readonly<Ref<boolean>>} isActive
 * @return {pauseBtn}
 * @typedef {Ref<'pause'|'play'>}
 * @summary watch isActive value, align pause/play button to display icon respectively
 */
watch(isActive, (newVal) => {
  pauseBtn.value = newVal ? "pause" : "play";
});

/**
 * @arg {Ref<Number>} isActive
 * @return {Ref<'slide-next'|'slide-prev'>} slideDirection
 * @summary wartch activeIdx value to determine the direction of transition
 */
watch(
  () => activeIdx!.value,
  (newVal, oldVal) => {
    if (
      newVal > oldVal ||
      (newVal === 0 && oldVal === props.cards.length - 1)
    ) {
      slideDirection.value = "slide-next";
    } else {
      slideDirection.value = "slide-prev";
    }
  }
);
</script>

<style scoped lang="scss">
.slot-container {
  display: grid;
  grid-template-columns: 100%;
  grid-template-rows: 100%;
}
.slot-wrapper {
  grid-row: 1;
  grid-column: 1;
}
.carousel-control {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 64px;
  height: 64px;
  border-radius: 50%;
  border: 1px solid var(--rds-light-4, #d0d0d0);
  background: var(--rds-light-3, #e8e8e8);
  cursor: pointer;

  &:focus {
    outline: 2px solid var(--rds-dark-3, #191919);
  }
}

:disabled {
  opacity: 0.5;
  cursor: auto;
  outline: none !important;
}

.controller-block {
  gap: 1rem;
}
.mobile-play-btn {
  background: transparent;
  border: none;
  width: 1rem;
  height: auto;
  padding: 0;
}
.mobile-progress-bar {
  position: relative;
  min-width: 7.875rem;
  &::before {
    content: "";
    position: absolute;
    bottom: 0;
    left: 0;
    height: 100%;
    // transition-property: width;
    background-color: var(--rds-secondary, #ffc627);
    width: var(--progress-wts, 50%);
  }
}
.slide-prev-enter-active,
.slide-prev-leave-active,
.slide-next-enter-active,
.slide-next-leave-active {
  transition: transform 600ms cubic-bezier(0.165, 0.84, 0.44, 1) 0s,
    opacity 600ms linear 0s;
}

.slide-prev-enter-from {
  transform: translate3d(calc(-100% - var(--rds-gutter-x)), 0px, 0px);
  opacity: 0;
}
.slide-prev-leave-to {
  transform: translate3d(calc(100% + var(--rds-gutter-x)), 0px, 0px);
  opacity: 0;
}
.slide-next-enter-from {
  transform: translate3d(calc(100% + var(--rds-gutter-x)), 0px, 0px);
  opacity: 0;
}
.slide-next-leave-to {
  transform: translate3d(calc(-100% - var(--rds-gutter-x)), 0px, 0px);
  opacity: 0;
}

@media (prefers-reduced-motion) {
  .slide-prev-enter-active,
  .slide-prev-leave-active,
  .slide-next-enter-active,
  .slide-next-leave-active {
    transition: transform 0 cubic-bezier(0.165, 0.84, 0.44, 1) 0s,
      opacity 0 linear 0s;
  }
}
</style>
