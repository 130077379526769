<template>
  <section class="container testimonial-columns-section">
    <h2 class="mb-space-md h2-medium">{{ title }}</h2>
    <div class="row">
      <div
        class="w-100 d-lg-flex column-container pb-space-sm overflow-hidden position-relative"
        ref="columnContainer"
        :style="columnContainerStyle"
      >
        <TransitionGroup :name="transitionDirection">
          <template v-for="(column, index) in items" :key="column.name">
            <div
              tabindex="0"
              class="column position-relative d-lg-flex p-lg-space-xs"
              @focus="handleSlideFocus(index, column)"
              @mouseover="handleSlideHover(index, column)"
              v-show="index == activeIndex || isDesktop"
              :class="index == activeIndex ? 'active col-lg-8' : 'col-lg-2'"
            >
              <div class="column-background">
                <img
                  class="rds-lazy"
                  :data-src="column?.image"
                  :alt="column?.imageAlt"
                />
              </div>
              <Transition>
                <div
                  v-if="index == activeIndex || isDesktop == false"
                  class="column-content d-flex justify-content-center mx-gutter shadow-sm"
                >
                  <div
                    class="bg-white px-space-xs py-space-sm px-lg-space-lg py-lg-space-md"
                  >
                    <div
                      class="quote fw-light mb-space-sm"
                      v-html="column?.quote"
                    ></div>
                    <p class="fw-bold fs-large mb-space-xxs">
                      {{ column?.name }}
                    </p>
                    <p class="fw-light text-dark-1 mb-space-xxs">
                      {{ column?.designation }}
                    </p>
                    <p class="fw-light fs-xs text-primary mb-0">
                      <span>
                        <font-awesome-icon icon="star" class="text-primary" />
                      </span>
                      {{ column?.designationLineTwo }}
                    </p>
                  </div>
                </div>
              </Transition>
              <Transition>
                <div
                  v-if="index !== activeIndex"
                  class="w-100 d-none d-lg-flex justify-content-start column-icon-wrapper"
                >
                  <div class="column-icon bg-white rounded-circle">
                    <font-awesome-icon
                      icon="quote-right"
                      class="text-primary h-100 w-100 p-space-xxs"
                    ></font-awesome-icon>
                  </div>
                </div>
              </Transition>
            </div>
          </template>
        </TransitionGroup>
      </div>

      <div class="col-12 d-lg-none mb-space-md">
        <div class="d-flex justify-content-center align-items-center">
          <button
            aria-label="previous slide"
            class="justify-content-center align-items-center carousel-control bg-light-3 border border-light-4 rounded-circle d-flex me-space-sm"
            :class="{ disable: activeIndex === 0 }"
            :disabled="activeIndex === 0"
            @click="handlePreviousSlide()"
          >
            <font-awesome-icon icon="chevron-left"></font-awesome-icon>
          </button>
          <button
            aria-label="next slide"
            class="justify-content-center align-items-center carousel-control bg-light-3 border border-light-4 rounded-circle d-flex"
            :class="{ disable: activeIndex === items.length - 1 }"
            :disabled="activeIndex === items.length - 1"
            @click="handleNextSlide()"
          >
            <font-awesome-icon icon="chevron-right"></font-awesome-icon>
          </button>
        </div>
      </div>
    </div>
  </section>
</template>

<script setup lang="ts">
import { ParagraphTestimonialImage } from "@/server/resolvers/resolvers-types";
import {
  analyticsComposable,
  type AnalyticsEventObject,
} from "@rds-vue-ui/analytics-gs-composable";
import {
  useResizeObserver,
  useSwipe,
  UseSwipeDirection,
  useWindowSize,
} from "@vueuse/core";

interface Props {
  items: ParagraphTestimonialImage[];
  title: string;
}

const props = withDefaults(defineProps<Props>(), {});

const activeIndex = ref(0);
const columnContainer = ref<HTMLElement>();
const { lengthX } = useSwipe(columnContainer, {
  onSwipeEnd(e: TouchEvent, direction: UseSwipeDirection) {
    if (
      isDesktop.value === false &&
      columnContainer.value &&
      Math.abs(lengthX.value) / columnContainer.value.offsetWidth >= 0.25
    ) {
      if (direction == "left") {
        handleNextSlide("swipe");
      } else if (direction == "right") {
        handlePreviousSlide("swipe");
      }
    }
  },
});
const columnContainerWidth = ref(0);
/**
 * Setting the width of all the columns so we can use it to set the column content width. We need to do this because it's absolutly positioned and needs a set width so it doesn't shrink when the column shrinks
 */
const columnContainerStyle = computed(() => {
  if (columnContainer.value) {
    return `--testimonial-column-container-width: ${columnContainerWidth.value}px`;
  }
});

// lets us indicate the direction of the transition on mobile
const transitionDirection = ref<"slide-previous" | "slide-next">(
  "slide-previous"
);
const { width: screenWidth } = useWindowSize();

const isDesktop = computed(() => {
  return screenWidth.value >= 992;
});

useResizeObserver(columnContainer, (entries) => {
  const entry = entries[0];
  columnContainerWidth.value = entry.contentRect.width;
});

const handleSlideFocus = (index: number, item: ParagraphTestimonialImage) => {
  if (isDesktop.value && activeIndex.value !== index) {
    activeIndex.value = index;
    const eventData: AnalyticsEventObject = {
      event: "collapse",
      action: "open",
      name: "onfocus",
      type: "focus",
      region: "main content",
      section: props.title?.toLowerCase(),
      text: item.name!.toLowerCase(),
    };
    analyticsComposable.trackEvent(eventData);
  }
};

const handleSlideHover = (index: number, item: ParagraphTestimonialImage) => {
  if (isDesktop.value && activeIndex.value !== index) {
    activeIndex.value = index;
    const eventData: AnalyticsEventObject = {
      event: "collapse",
      action: "open",
      name: "onhover",
      type: "hover",
      region: "main content",
      section: props.title?.toLowerCase(),
      text: item.name!.toLowerCase(),
    };
    analyticsComposable.trackEvent(eventData);
  }
};

const handlePreviousSlide = (action: "click" | "swipe" = "click") => {
  if (activeIndex.value > 0) {
    activeIndex.value--;
    transitionDirection.value = "slide-previous";
    analyticsComposable.trackSelectEvent(
      "onclick",
      action,
      "carousel",
      "main content",
      props.title.toLowerCase(),
      action == "click"
        ? "left chevron"
        : props.items[activeIndex.value]!.name!.toLowerCase()
    );
  }
};

const handleNextSlide = (action: "click" | "swipe" = "click") => {
  if (activeIndex.value < props.items.length - 1) {
    activeIndex.value++;
    transitionDirection.value = "slide-next";
    analyticsComposable.trackSelectEvent(
      "onclick",
      action,
      "carousel",
      "main content",
      props.title.toLowerCase(),
      action == "click"
        ? "right chevron"
        : props.items[activeIndex.value]!.name!.toLowerCase()
    );
  }
};
</script>

<style scoped>
/* Setting the gap between columns so it can be reused */
.testimonial-columns-section {
  --testimonial-column-gap: 25px;
  --testimonial-column-gap-gutter: calc(var(--testimonial-column-gap) * 2 / 3);
}

.column-container {
  display: grid;
  grid-template-columns: 100%;
  grid-template-rows: 100%;
}

.column {
  height: 100%;
  min-height: 587px;
  display: grid;
  grid-row: 1;
  grid-column: 1;
  grid-template-columns: 100%;
  grid-template-rows: 30% 30% 40%;
  flex-grow: 1;
  grid-template-areas:
    "header"
    "middle"
    "footer";
}

.column-background {
  grid-row: header / middle;
  display: flex;
  grid-column: 1;
  width: 100%;
  height: auto;
  position: relative;
  & img {
    position: absolute;
    z-index: -1;
    object-fit: cover;
    object-position: top;
    height: 100%;
    width: 100%;
  }
}

.column-content {
  grid-row: middle / footer;
  grid-column: 1;
  width: calc(100% - var(--rds-gutter));
}
.quote {
  font-size: 1rem;
  line-height: 1.4;
}
.carousel-control {
  aspect-ratio: 1;
  height: 64px;
}

@media (max-width: 991px) {
  .column-content {
    align-self: end;
  }

  /* Mobile transition animations start */
  .slide-previous-enter-active,
  .slide-previous-leave-active,
  .slide-next-enter-active,
  .slide-next-leave-active {
    transition: transform 600ms cubic-bezier(0.165, 0.84, 0.44, 1) 0s;
  }

  .slide-previous-enter-from {
    transform: translate3d(calc(-100% - var(--rds-gutter-x)), 0px, 0px);
  }
  .slide-previous-leave-to {
    transform: translate3d(calc(100% + var(--rds-gutter-x)), 0px, 0px);
  }
  .slide-next-enter-from {
    transform: translate3d(calc(100% + var(--rds-gutter-x)), 0px, 0px);
  }
  .slide-next-leave-to {
    transform: translate3d(calc(-100% - var(--rds-gutter-x)), 0px, 0px);
  }

  @media (prefers-reduced-motion) {
    .slide-previous-enter-active,
    .slide-previous-leave-active,
    .slide-next-enter-active,
    .slide-next-leave-active {
      transition: transform 0 cubic-bezier(0.165, 0.84, 0.44, 1) 0s;
    }
  }
}

@media (min-width: 576px) {
  .column {
    grid-template-rows: 35% 30% 35%;
  }
}
@media (min-width: 768px) {
  .column {
    grid-template-rows: 20% 50% 30%;
  }
}

@media (min-width: 992px) {
  .column-container {
    gap: var(--testimonial-column-gap);
  }
  .column {
    justify-content: center;
    align-items: end;
    overflow: hidden;
    position: relative;
    width: calc(16.66666667% - var(--testimonial-column-gap-gutter));
    transition: width 600ms ease-in-out;
    @media (prefers-reduced-motion) {
      transition: width 0ms;
    }
  }
  .column.active {
    width: calc(66.66666667% - var(--testimonial-column-gap-gutter));
  }
  .column-background {
    overflow: hidden;
    z-index: -1;
    top: 0;
    position: absolute;
    height: 100%;
    /* equivilent to ~8 cols */
    width: calc(
      (var(--testimonial-column-container-width) / 1.5) -
        (var(--rds-gutter-x) / 2)
    );
  }
  .column-content {
    position: absolute;
    width: calc(
      (var(--testimonial-column-container-width) / 2) -
        (var(--rds-gutter-x) / 2)
    );
  }
  .quote {
    font-size: 1.125rem;
  }
  .column-icon {
    aspect-ratio: 1;
    height: 46px;
  }
  .v-enter-active,
  .v-leave-active {
    position: absolute !important;
    transition: opacity 500ms ease-in-out, transform 600ms ease-in-out,
      width 600ms;
    transform-origin: bottom center;
  }

  .v-leave-active.column-icon-wrapper,
  .v-enter-active.column-icon-wrapper {
    left: 1rem;
  }

  .v-leave-to,
  .v-enter-from {
    opacity: 0;
    bottom: 1rem !important;
  }

  .v-enter-from.column-content,
  .v-leave-to.column-content {
    bottom: 1rem !important;
    margin-top: auto;
    margin-bottom: 0;
    transform: scale(0.5);
  }
  @media (prefers-reduced-motion) {
    .v-enter-active,
    .v-leave-active {
      transition: opacity 0ms, transform 0ms, width 0ms;
    }
  }
}
</style>
