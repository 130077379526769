<template>
  <section class="container">
    <div
      class="row flex-wrap justify-content-center my-lg-space-xxl my-space-lg"
    >
      <div
        class="col-lg-6 col-12 d-flex justify-content-lg-start justify-content-start mt-lg-space-sm"
      >
        <div>
          <img class="rds-lazy mb-sm-space-md" :data-src="image" :alt="alt" />
        </div>
      </div>
      <div
        class="col-lg-5 col-12 px-lg-space-xl pt-space-md pt-lg-0 pt-md-space-md"
      >
        <h2 class="h2-medium mb-space-xs">{{ title }}</h2>
        <div class="fs-large" v-html="description"></div>
        <NuxtLink
          :to="cta.uri"
          class="text-decoration-underline fw-bold fs-large"
          @click="handleClick"
          >{{ cta.text }}</NuxtLink
        >
      </div>
    </div>
    <hr class="d-flex my-space-md mt-lg-space-xxl custom-line" />
  </section>
</template>

<script setup lang="ts">
import { ParagraphHtmlLink } from "@/server/resolvers/resolvers-types";
import {
  analyticsComposable,
  type AnalyticsEventObject,
} from "@rds-vue-ui/analytics-gs-composable";

const props = defineProps<{
  title: string;
  description: string;
  image: string;
  alt: string;
  cta: ParagraphHtmlLink;
}>();

const handleClick = (event: Event) => {
  const { title, cta } = props;
  const eventObject: AnalyticsEventObject = {
    event: "link",
    action: "click",
    name: "onclick",
    type: "internal link",
    region: "main content",
    section: title.toLowerCase(),
    text: cta.text?.toLowerCase(),
  };
  analyticsComposable.trackEvent(eventObject);
};
</script>

<style scoped>
img {
  display: block;
  max-width: 100%;
  height: auto;
}

.custom-line {
  height: 2px;
  background-color: var(--rds-light-4);
}
</style>
