<template>
  <section class="bg-dark-3" :id="id">
    <div class="container">
      <div class="row gx-0">
        <div class="col-12">
          <div class="text-white pt-space-lg pb-space-md position-relative">
            <div class="pb-space-md pb-lg-space-lg">
              <span>
                <h2 class="h2-large d-inline-block pe-space-sm">
                  Find information about
                </h2>

                <RdsDropdown
                  :id="`sea-dropdown-${id}`"
                  class="explore-btn d-inline-block position-relative"
                  buttonSize="lg"
                  button-classes=" h2-large d-flex align-items-center"
                  button-variant="white"
                  :text="items[selectedItemIndex]?.title"
                  menu-classes="minw-100 bg-light-3"
                  no-caret
                  @toggle="
                    selectItemClick(items[selectedItemIndex]?.title, $event)
                  "
                >
                  <template #button-content="{ active }">
                    <span class="pe-space-xxxs">{{
                      items[selectedItemIndex].title
                    }}</span>
                    <FontAwesomeIcon
                      :aria-label="active ? 'Close dropdown' : 'Open dropdown'"
                      :icon="active ? faChevronUp : faChevronDown"
                      class="text-secondary h3-large"
                    ></FontAwesomeIcon>
                  </template>
                  <RdsDropdownItem
                    v-for="(item, index) in informationItems"
                    :key="index"
                    @click="updateSelectedItem(item, index, $event)"
                    link-class="fw-bold h2-large"
                  >
                    {{ item }}</RdsDropdownItem
                  >
                </RdsDropdown>
              </span>
            </div>

            <div class="list-container position-relative w-100">
              <Transition name="fade" mode="out-in">
                <!-- list of items -->
                <div class="list" :key="selectedItemIndex">
                  <ul class="list-unstyled mb-0 pl-0 row text-white">
                    <li
                      v-for="(item, index) in listItems"
                      :key="index"
                      class="col-lg-3 col-sm-12 d-flex"
                    >
                      <!-- link -->
                      <a
                        :href="item?.uri"
                        :title="item?.text"
                        :aria-label="
                          items[selectedItemIndex]?.title! + '-' + item?.text
                        "
                        :target="item?.target!"
                        class="fw-bold h3-large text-white p-space-sm mb-space-sm mb-lg-space-md pe-space-md text-white flex-grow-1 border border-white"
                        @click="
                          handleLinkClick(
                            {
                              ...item,
                              component: items[selectedItemIndex].title,
                            },
                            $event
                          )
                        "
                      >
                        {{ item?.text }}
                      </a>
                    </li>
                  </ul>
                </div>
              </Transition>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script setup lang="ts">
import {
  ParagraphHtmlLink,
  ParagraphImageItem,
} from "@/server/resolvers/resolvers-types";
import { faChevronDown, faChevronUp } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";
import { RdsDropdown, RdsDropdownItem } from "@rds-vue-ui/rds-dropdown";

export interface ExploreItemLink {
  text: string;
  uri: string;
  target: string;
}

export interface ExploreItemLinkEvent extends ParagraphHtmlLink {
  component: string;
}

interface Props {
  items: ParagraphImageItem[];
  id: string;
}

const props = withDefaults(defineProps<Props>(), {});

// events
const emits = defineEmits<{
  /**
   *  Emitted when a link is clicked.
   */
  (e: "linkClick", event: Event, item: ExploreItemLinkEvent): void;
  /**
   *  This event is fired when we click on dropdown selection.
   */
  (e: "selectItem", event: Event, item: string, state: string): void;
  /**
   *  This event is fired when we clik on dropdownlist.
   */
  (e: "updateItem", event: Event, item: string): void;
}>();

const selectedItemIndex = ref(0);
const informationItems = ref<string[]>([]);

const listItems = computed(() => {
  const { items } = props;
  let allItemTypes: ParagraphHtmlLink[] = [];
  if (items.length > 0) {
    allItemTypes = items[selectedItemIndex.value].listItems
      ? items[selectedItemIndex.value].listItems
      : [];
  }
  return allItemTypes;
});

const handleLinkClick = (item: ExploreItemLinkEvent, event: Event) => {
  event.preventDefault();
  emits("linkClick", event, item);
};

const selectItemClick = (selectedItem: string, event: Event) => {
  const dropdown = event.currentTarget as HTMLElement;
  const isOpen = dropdown.getAttribute("active") === "true";
  emits("selectItem", event, selectedItem, isOpen ? "close" : "open");
};

const updateSelectedItem = (item: string, index: number, event: Event) => {
  selectedItemIndex.value = index;
  emits("updateItem", event, item);
};

onMounted(() => {
  informationItems.value = props.items.map((obj) => obj.title);
});
</script>

<style lang="scss" scoped>
.list-container {
  display: grid;
  grid-template-columns: 100%;
  grid-template-rows: 100%;
}
.dropdown {
  display: inline-block;
  font-size: 16px;
  cursor: pointer;
}

.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.25s ease;
  grid-row: 1;
  grid-column: 1;
}

.fade-enter-from,
.fade-leave-to {
  opacity: 0;
}

:deep(.minw-100) {
  min-width: 100%;
}

:deep(.minw-100):hover {
  background-color: var(--rds-light-1);
}

:deep(.dropdown-toggle-no-caret) {
  background: none;
  color: var(--rds-secondary);
  text-decoration: underline;
  border: none;
  display: block;
  padding: 0px;
  --rds-btn-active-color: var(--rds-secondary);
  --rds-btn-active-bg: transparent;
  --rds-btn-active-border-color: transparent;
}

:deep(.svg-inline--fa) {
  color: var(--rds-secondary) !important;
  vertical-align: middle;
}

@media (min-width: 992px) {
  :deep(.dropdown-toggle-no-caret) {
    text-underline-offset: 10px;
  }
}
</style>
