<template>
  <section class="container py-lg-space-xxl py-space-lg">
    <div class="row justify-content-between">
      <div class="col-lg-4 col-12 postion-relative d-flex flex-column bg-white">
        <div class="col-12 p-lg-0">
          <div class="underline mb-space-sm"></div>
          <h2 class="mb-space-sm mb-lg-space-md h2-large">
            {{ title }}
          </h2>
          <div class="mb-space-md" v-html="description"></div>
        </div>
      </div>
      <div class="col-lg-7 col-12">
        <div
          v-for="(data, index) in items"
          class="postion-relative d-flex flex-column justify-content-center bg-white"
        >
          <NuxtLink
            :to="data?.detailPage"
            class="d-flex align-items-center justify-content-between interest-areas py-space-xs"
            :class="{ 'pt-0': index == 0 }"
            @click="
              handleProgramInterest(
                'browse online programs by interest',
                data?.title
              )
            "
          >
            <p class="col-11 programs-wrapper mb-0">
              <span
                class="h2-medium text-dark-1 program-title"
                :aria-label="data?.title!"
              >
                {{ data?.title }}
              </span>
            </p>
            <div class="icon-wrapper col-1">
              <FontAwesomeIcon
                icon="fa-solid fa-arrow-right"
                class="cta-icon-class"
                size="2x"
              ></FontAwesomeIcon>
            </div>
          </NuxtLink>
          <div
            v-if="index !== props.items.length - 1"
            class="custom-line"
          ></div>
        </div>
      </div>
    </div>
  </section>
</template>

<script setup lang="ts">
import { InterestAreaType } from "@/server/resolvers/resolvers-types";
import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";
import { analyticsComposable } from "@rds-vue-ui/analytics-gs-composable";

export interface InterestArea {
  text: string;
  uri: string;
  target: string;
}

interface Props {
  items: InterestAreaType[];
  title: string;
  description: string;
}

const props = withDefaults(defineProps<Props>(), {});

const handleProgramInterest = (section: string, text: string) => {
  analyticsComposable.trackLinkEvent(
    "internal link",
    "main content",
    section.toLowerCase(),
    text.toLowerCase()
  );
};
</script>

<style lang="scss" scoped>
.interest-areas:focus {
  outline: 0;
}

.icon-wrapper {
  visibility: hidden;
}

.custom-line {
  height: 2px;
  background-color: var(--rds-light-4);
}

.underline {
  background: var(--rds-secondary);
  height: 10px;
  width: 93px;
}

a {
  text-decoration: none;
}

/* Medium devices (tablets, 768px and up) */
@media (min-width: 768px) {
  .interest-areas:hover .programs-wrapper,
  .interest-areas:focus .programs-wrapper {
    transform: translateX(10px);
  }

  .interest-areas .icon-wrapper {
    text-align: center;
    opacity: 0.25;
    transition: opacity 0.5s ease;
  }
  .interest-areas:hover .icon-wrapper,
  .interest-areas:focus .icon-wrapper {
    visibility: visible;
    display: flex;
    align-items: center;
    justify-content: center;
    background: var(--rds-secondary);
    border: none;
    border-radius: 50%;
    display: flex;
    height: 40px;
    width: 40px;
    opacity: 1; /* Increased opacity on hover */
    transition: opacity 0.5s ease;
    border: 1px solid white;
  }

  .interest-areas .cta-icon-class {
    color: var(--rds-dark-3);
    height: 14px;
    width: 100%;
  }
  .interest-areas:hover .program-title,
  .interest-areas:focus .program-title {
    color: var(--rds-dark-3) !important;
  }
  .interest-areas .programs-wrapper {
    transition: transform 0.5s ease-in-out;
  }
}
</style>
